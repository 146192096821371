@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Space Grotesk', 'Inter'
    monospace;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('../public/fonts/space-grotesk/SpaceGrotesk-Bold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('../public/fonts/space-grotesk/SpaceGrotesk-SemiBold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('../public/fonts/space-grotesk/SpaceGrotesk-Medium.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('../public/fonts/space-grotesk/SpaceGrotesk-Regular.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/inter/Inter-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/inter/Inter-Bold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/inter/Inter-SemiBold.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/inter/Inter-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/inter/Inter-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1, h2, h3 {
  margin: 0;
  font-family: 'Space Grotesk';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 11px;
}

.scrollbar-dark::-webkit-scrollbar-track {
  background: #202020;
}

.scrollbar-light::-webkit-scrollbar-track {
  background: #F7F0EE;
}

.timeline-stacking::-webkit-scrollbar {
  display: none;
}

.timeline-stacking {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar-thumb {
  background: #01c1fe;
  border-radius: 3px;
  height: 80px;
}

::-webkit-scrollbar-thumb:hover {
  background: #01c1fe;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}